import '../styles/pages/robotics.scss';
import * as React from "react"
import {HeadFC, navigate} from "gatsby"
import {useEffect, useState} from "react";
import Slider from "react-slick";
import {
    BigLogoImage, LoadingGif,
    mrRobot1,
    mrRobot2,
    mrRobot3,
    mrRobotMain,
    mrRobotIcon1,
    mrRobotIcon2,
    mrRobotIcon3
} from "../images";

import useAnalytics from "../hooks/useAnalytics";


const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true
};

const MrAeerPage = () => {
    const [isLoading, setLoading] = useState(true);
    const {initAnalytics, saveEmail} = useAnalytics();

    // temporary solution
    const [faq1, setFaq1] = useState(false);
    const [faq2, setFaq2] = useState(false);
    const [faq3, setFaq3] = useState(false);

    useEffect(() => {
        initAnalytics();
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    // useEffect(() => {
    //     PageService.getCourseById(1).then((result) => {
    //         setData(result.data.data.attributes.Content)
    //     }).catch(() => {
    //     }).finally(() => {
    //         setLoading(false)
    //     });
    // }, []);

    return (
        <main>
            {
                isLoading && (
                    <div className="loadWrap">
                        <div className="loadWrapLogo">
                            <img width={180} src={BigLogoImage} alt=""/>
                            <div className="loadWrapText">
                                <img width={16} src={LoadingGif} alt=""/>
                                Loading..
                            </div>
                        </div>
                    </div>
                )
            }

            <section className="robotPage">
                <div className="robotPageHeader">
                    <div className="container">
                        <div className="robotPageHeaderLogo">
                            <img src={BigLogoImage} alt="" />
                        </div>
                        {/* <nav className="robotPageHeaderNav">
                            <ul>
                                <li>Block 1</li>
                                <li>Block 2</li>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </nav> */}
                    </div>
                </div>

                <div className="robotPageMain">
                    <div className="container">
                        <h1 className="robotPageMainTitle">Ameca</h1>
                        <p className="robotPageMainSubtitle">The Future Face Of Robotics</p>
                        <img src={mrRobotMain} alt="" className="robotPageMainImage" />
                    </div>
                </div>
                        
                <div className="robotPageAbout white">
                    <div className="container">
                        <div className="robotPageAboutBox">
                            
                            <div className="robotPageAboutInfo right">
                                <p className="robotPageAboutTitle">A platform of the future</p>
                                <p className="robotPageAboutText">Ameca is the world’s most advanced human shaped robot representing the forefront of human-robotics technology.</p>
                                <p className="robotPageAboutText">Designed specifically as a platform for development into future robotics technologies, Ameca is the perfect humanoid robot platform for human-robot interaction.</p>
                                <p className="robotPageAboutText">We focus on bringing you innovative technologies, which are reliable, modular, upgradable and easy to develop upon.</p>
                            </div>
                            <img src={mrRobot1} alt="" className="robotPageAboutImage" />

                        </div>
                    </div>
                </div>

                <div className="robotPageAbout grey">
                    <div className="container">
                        <div className="robotPageAboutBox">

                            <img src={mrRobot2} alt="" className="robotPageAboutImage" />
                            <div className="robotPageAboutInfo left">
                                <p className="robotPageAboutTitle">AI x AB</p>
                                <p className="robotPageAboutText">Human-like artificial intelligence needs a human-like artificial body (AI x AB).</p>
                                <p className="robotPageAboutText">Artificial intelligence and machine learning systems can be tested and developed on Ameca alongside our powerful <span>Tritium</span> robot operating system.</p>
                                <p className="robotPageAboutText">The Ameca hardware is a development based on our own research into humanoid robotics and built on our advanced <span>Mesmer</span> technology.</p>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <div className="robotPageAbout white">
                    <div className="container">
                        <div className="robotPageAboutBox">
                            
                            <div className="robotPageAboutInfo right">
                                <p className="robotPageAboutTitle">Show off</p>
                                <p className="robotPageAboutText">As well as a development platform, Ameca is also a great attraction, just like our <span>Mesmer</span> and <span>RoboThespian</span> robots. Wow your customers or visitors at an event or visitor attraction.</p>
                                <p className="robotPageAboutText">Reliability is key, and all our robots are built to last in action in the real world, not just in the lab.</p>
                                <p className="robotPageAboutText">The modular architecture allows for future upgrades, both physically and software to enhance Ameca’s abilities, all without having to fork out for an entire new robot.</p>
                                <p className="robotPageAboutText">Ameca is available for <span>purchase</span> or <span>event rental.</span></p>
                            </div>
                            <img src={mrRobot3} alt="" className="robotPageAboutImage" />

                        </div>
                    </div>
                </div>

                <div className="robotPageServ">
                    <h2 className="robotPageServTitle">Todays robotics for tomorrows AI</h2>
                    <div className="container">
                        <div className="robotPageServBox">

                            <div className={`robotPageServItem`} >
                                <div className="robotPageServIcon">
                                    <img src={mrRobotIcon1} alt="" />
                                </div>
                                <span className="robotPageServSubtitle">Modular by design</span>
                                <p className="robotPageServText">Both hardware and software is modular, making it easily upgradable.</p>
                                <p className="robotPageServText">All modules can run independently so you can have just a head, or even only an arm. No need for a full robot.</p>
                            </div>

                            <div className={`robotPageServItem`} >
                                <div className="robotPageServIcon">
                                    <img src={mrRobotIcon2} alt="" />
                                </div>
                                <span className="robotPageServSubtitle">Head in the clouds</span>
                                <p className="robotPageServText">Built from the ground up with a cloud connected focus, all our robots can benefit from the latest and greatest the tech community have to offer.</p>
                                <p className="robotPageServText">Gain access to all the robots data, control it as your personal avatar, animate and simulate, all available from anywhere in the world.</p>
                            </div>

                            <div className={`robotPageServItem`}>
                                <div className="robotPageServIcon">
                                    <img src={mrRobotIcon3} alt="" />
                                </div>
                                <span className="robotPageServSubtitle">Natural motion</span>
                                <p className="robotPageServText">Smooth, lifelike motion and advanced facial expression capabilities means Ameca can strike an instant rapport with anybody.</p>
                                <p className="robotPageServText">Ameca is the perfect platform to develop interaction between us humans and any metaverse or digital realm.</p>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="robotPageVideo">
                    <div className="container">
                        <h2 className="robotPageVideoTitle">Ameca in action</h2>
                        <div className="robotPageVideoBox">
                            <div className="robotPageVideoItem">
                                <iframe src="https://player.vimeo.com/video/651929733" allowFullScreen></iframe>
                            </div>
                            <div className="robotPageVideoItem">
                                <iframe src="https://player.vimeo.com/video/652071809" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="robotPageReview">
                    <div className="container">
                        <h2 className="robotPageServTitle">Todays robotics for tomorrows AI</h2>
                        <Slider className="robotPageReviewSlider" {...settings}>

                            <div className="robotPageReviewSlide">
                                <span className="robotPageReviewSubtitle">“Engineered Arts have been very supportive”</span>
                                <p className="robotPageReviewText">"As far as I have worked with various robotic platforms, RoboThespian is a unique one in terms of its design and features. The well-designed and elegant structure of RoboThespian allows mimicking human movements in a seamless way. The simplicity of its backend system in programming complex tasks has enabled us to teach students to program complex movements scenarios and behaviours. The team of Engineered Arts have been very supportive in guiding us through the installation and programming steps. Due to these facts, I would highly recommend Engineered Arts technologies for any robotics lab"</p>
                                <p className="robotPageReviewRemark">Dr. Abdullah Almeshal, College of Technological Studies – RAiLab, Kuwait.</p>
                            </div>
                            
                            <div className="robotPageReviewSlide">
                                <span className="robotPageReviewSubtitle">We were fantastically supported by Marcus</span>
                                <p className="robotPageReviewText">"We worked with Engineered Arts to develop custom content for our RoboThespian, including our own touchscreen interface design. We were fantastically supported by Marcus and the team throughout the customisation process. We were able to create the baseline content wholly through user testing ideas with online participants using Virtual RoboThespian via video calls. It was a really useful tool for engaging with users virtually during the pandemic and allowed us to tailor the content in terms of both script, tone and movement. The whole process gave us a fantastic amount of freedom to create something that hasn’t existed in a science centre before! Our early visitors have said "It’s fascinating to see a human sized robot" and "He's really visually striking this is the exhibit that resonates the most"</p>
                                <p className="robotPageReviewRemark">Rose O’Reilly, Content Developer, We The Curious, Bristol UK</p>
                            </div>

                            <div className="robotPageReviewSlide">
                                <span className="robotPageReviewSubtitle">Grandparents and toddlers singing, laughing and creating memories together</span>
                                <p className="robotPageReviewText">Our RoboThespian has been greeting visitors at the entrance to Carnegie Science Center’s “RoboWorld™” for over a decade, introducing the gallery, explaining robot basics, conducting sing-alongs, and cracking bad jokes to hundreds of thousands of guests. Technically, the ease of maintenance and the responsivity of the Engineered Arts team ensure his ‘down time’ has been minimal; any software glitches (few and far between) or updates can be handled remotely, and the ordering and delivery of occasional replacement parts is swift and efficient. And the interface kiosk for guest use is intuitive and engaging; my offices are right off the RoboWorld gallery, so I have the benefit of seeing a wide demographic of visitors enthralled by his antics. Especially satisfying is seeing multi-generation engagement – grandparents and toddlers signing, laughing and creating memories together. “Andy Roid” (a nod to both our founder Andrew Carnegie and ‘androids’) is a favorite of both staff and guests.</p>
                                <p className="robotPageReviewRemark">Dennis Bateman, Director, Carnegie Science Center</p>
                            </div>

                            <div className="robotPageReviewSlide">
                                <span className="robotPageReviewSubtitle">I cannot thank Engineered Arts enough!</span>
                                <p className="robotPageReviewText">"I cannot thank Engineered Arts enough for their fabulous robot. The robot itself is top quality design and engineering, and we are constantly surprised by the elegance of the design and the quality of the make and materials. It is astonishingly reliable. The technical support is as outstanding as the robot, with experts able to direct and assist with the running on every detail of the robot. We were concerned about having enough technical ability to own a robot. We needn't have worried. The interface is extremely intuitive, allowing us to really be creative with full confidence in the robot and the supporting software. Our robot is very well received by people, and allows us full freedom in our creative industry. Both the robot and the team are brilliant, and I cannot recommend highly enough."</p>
                                <p className="robotPageReviewRemark">Lucy Seal, Curator of Ai-Da Robot. The world’s first ultra-realistic robot artist</p>
                            </div>

                            <div className="robotPageReviewSlide">
                                <span className="robotPageReviewSubtitle">“It´s easier than Ikea to set up!”</span>
                                <p className="robotPageReviewText">"When we approached Engineered Arts about RoboThespian we had a bold project in mind, we wanted to ask local school children to create content for the robot to engage the local community around green energy projects in Scotland. Engineered Arts took the children's ideas and created bespoke content that visitors can play from our touchscreen console that was supplied. Our installation engineer said "It´s easier than Ikea to set up, It was real fun! My compliments, I was pretty impressed!"</p>
                                <p className="robotPageReviewRemark">Bryan Snelling, Aberdeen Science Centre</p>
                            </div>

                        </Slider>
                    </div>
                </div>

                <div className="robotPageFaq">
                    <div className="container">
                        <h2 className="robotPageVideoTitle">What people ask</h2>
                        <div className="robotPageFaqBox">
                            <div className={`robotPageFaqItem ${faq1 ? 'active' : ''}`} onClick={() => setFaq1(!faq1)}>
                                <div className="robotPageFaqMore"></div>
                                <p className="robotPageFaqSubtitle">Can Ameca walk?</p>
                                <p className="robotPageFaqText">Not yet. There are many hurdles to overcome before Ameca can walk. Walking is a difficult task for a robot, and although we have done research into it, we have not created a full walking humanoid. <br /> However, Ameca is a modular robot, we plan on upgrading its abilities over time so one day Ameca will walk.</p>
                            </div>
                            <div className={`robotPageFaqItem ${faq2 ? 'active' : ''}`} onClick={() => setFaq2(!faq2)}>
                                <div className="robotPageFaqMore"></div>
                                <p className="robotPageFaqSubtitle">Is Ameca AI?</p>
                                <p className="robotPageFaqText">A big question. Ameca contains some software which can be described as ‘artificially intelligent’, but the question is bigger than that, check out our explanation of the difference between robots and AI. The main purpose of Ameca is to be a platform for developing AI. We love designing and building robots, we’ll leave it to you and all the other amazing (naturally intelligent) brains out there to create the AI and machine learning alogrithms and see how far we can progress the technology together.</p>
                            </div>
                            <div className={`robotPageFaqItem ${faq3 ? 'active' : ''}`} onClick={() => setFaq3(!faq3)}>
                                <div className="robotPageFaqMore"></div>
                                <p className="robotPageFaqSubtitle">What support do you give?</p>
                                <p className="robotPageFaqText">If anything doesn’t go to plan, our engineers can access and troubleshoot your robot remotely, instantly diagnosing the problem and often fixing any issue on the spot, even something as small as an eyelid movement. We are also happy to give development support for Ameca and the Tritium system</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="robotPageContact">
                    <div className="container">
                        <h2 className="robotPageVideoTitle">What would you like to ask us?</h2>
                        <p className="robotPageContactDescr">
                            See how Ameca can help with AI interaction development, or serve as a high-tech <br /> demo of your technological prowess.
                            </p>
                        <div className="robotPageContactForm">
                            <div className="robotPageContactInput">
                                <input type="text" placeholder="Your Name" />
                            </div>
                            <div className="robotPageContactInput">
                                <input type="text" placeholder="Your Email" />
                            </div>
                            <div className="robotPageContactSelect">
                                <label htmlFor="selectFirst" className="robotPageContactLabel">Subject (please select)</label>
                                <select name="" id="selectFirst">
                                    <option value="">---</option>
                                    <option value="Sales">Sales</option>
                                    <option value="Rentals">Rentals</option>
                                    <option value="Press">Press</option>
                                    <option value="Product information">Product information</option>
                                    <option value="Investor relations">Investor relations</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="robotPageContactSelect">
                                <label htmlFor="selectSecond" className="robotPageContactLabel">Business type (Note: we do not currently sell to individuals)</label>
                                <select name="" id="selectSecond">
                                    <option value="">---</option>
                                    <option value="Company">Company</option>
                                    <option value="Academic">Academic</option>
                                    <option value="Media">Media</option>
                                    <option value="Individual">Individual</option>
                                    <option value="Investor">Investor</option>
                                    <option value="Other">Other</option>
                                </select>
                            </div>
                            <div className="robotPageContactTextarea">
                                <textarea name="" id="" placeholder="Message"></textarea>
                            </div>
                            <button className="robotPageContactBtn">Submit</button>
                        </div>
                    </div>
                </div>

            </section>
            
        </main>
    )
}

export default MrAeerPage

export const Head: HeadFC = () => <title>AEER Platform</title>
